import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import QuoteBuilder from "../components/QuoteBuilder";

class Page extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: "/quote-builder/",
        }}
        path={"a"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <QuoteBuilder products={this.props.data.allWpProduct} />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allWpProduct {
      edges {
        node {
          id
          title
          uri
          product {
            code
            shortDescription
            variations {
              variantName
              variantCode
              option1Name
              option1Value
              option1Image {
                id
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              option1Icon {
                localFile {
                  publicURL
                }
              }
              option2Name
              option2Value
              option2Image {
                id
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              option2Icon {
                localFile {
                  publicURL
                }
              }
              option3Name
              option3Value
              option3Image {
                id
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              option3Icon {
                localFile {
                  publicURL
                }
              }
              option4Name
              option4Value
              option4Image {
                id
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              option4Icon {
                localFile {
                  publicURL
                }
              }
            }
            productGallery {
              id
              sourceUrl
              localFile {
                id
                publicURL
              }
            }
          }
          brands {
            nodes {
              name
              BrandExtras {
                logo {
                  id
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page;
