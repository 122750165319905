import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Cookies from "js-cookie";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

import { removeFromWishlist } from "../../app/actions";
import styles from "./styles";
import qbContact from "../../images/qb-contact.jpg";
import logo from "../../images/tyde-logo.png";
import qbFaq from "../../images/qb-faq.jpg";

import Breadcrumb from "../Breadcrumb";
import { CgbImageLinkBlock } from "../blocks/CgbImageLinkBlock";
import Form from "./Form";

import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock";
import { isBrowser } from "../../helpers";

import MiniSearch from "../ProductSearch/MiniSearch";
import { CgbPopularProductsBlock } from "../blocks/CgbPopularProductsBlock";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromWishlist: (id) => dispatch(removeFromWishlist(id)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const Pdf = ({ products }) => {
  if (!isBrowser) return null;

  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            backgroundColor: "#19365B",
            padding: "12px",
            textAlign: "center",
            marginBottom: "24px",
          }}
        >
          <Image
            src={logo}
            style={{
              width: "88px",
              height: "auto",
              margin: "0 auto",
            }}
          />
        </View>

        <View
          style={{
            margin: "10px",
            padding: "10px",
          }}
        >
          {products.map((product, index) => {
            let image = logo;
            if (
              product?.data?.product?.productGallery?.[0].localFile.publicURL
            ) {
              image =
                product?.data?.product?.productGallery[0].localFile.publicURL;
            }

            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  padding: "24px 0",
                  borderBottom: "1px solid #E7EAEE",
                }}
              >
                <Image
                  src={image}
                  style={{
                    width: "75px",
                    height: "auto",
                    marginRight: "12px",
                  }}
                />
                <View>
                  {product?.data?.brands?.nodes[0]?.BrandExtras?.logo?.localFile
                    ?.publicURL && (
                    <Image
                      src={
                        product?.data?.brands?.nodes[0]?.BrandExtras?.logo
                          ?.localFile?.publicURL
                      }
                      style={{
                        width: "60px",
                        height: "auto",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                  <Text
                    style={{
                      fontWeight: "bold",
                      lineHeight: "2",
                      fontSize: "12px",
                      color: "#19365B",
                    }}
                  >
                    {product?.data?.title}
                  </Text>
                  <Text
                    style={{
                      color: "#19365B",
                      opacity: ".3",
                      fontSize: "8px",
                      lineHeight: "1.5",
                      marginBottom: "10px",
                    }}
                  >
                    {product?.code}
                  </Text>
                  <Text
                    style={{
                      opacity: ".75",
                      fontSize: "8px",
                      lineHeight: "1.5",
                    }}
                  >
                    {product?.data?.product?.shortDescription}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

function QuoteBuilder({ products, wishlist, removeFromWishlist }) {
  const classes = useStyles();

  React.useEffect(() => {
    Cookies.set("wishlist", wishlist);
  }, [wishlist]);

  const p = products.edges.map((product) => product?.node);

  const filteredProducts = wishlist.map((item) => {
    let result = {};

    p.forEach((pr) => {
      if (!pr.product) return;
      if (pr.product?.code === Number(item)) {
        result = {
          data: pr,
          code: item,
        };
      }

      if (pr.product?.variations) {
        for (var i = 0; i < pr.product.variations.length; i++) {
          if (pr.product.variations[i].variantCode === item) {
            result = {
              data: pr,
              code: item,
              image: pr.product.variations[i].option2Image ?? pr.product.variations[i].option1Image,
            };
          }
        }
      }
    });

    return result;
  });

  return (
    <>
      <Breadcrumb
        type="category"
        current={{
          title: "Quote Builder",
          slug: "quote-builder",
          uri: "/quote-builder",
        }}
      />
      <section className={classes.productListing}>
        <div className="listing">
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: classes.header,
              content: "Quote Builder",
              level: 1,
              textColor: "",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />

          {filteredProducts.length > 0 ? (
            <>
              <div className="products">
                {filteredProducts.map((product, index) => {
                  if (!product?.data) return null;

                  let image = logo;
                  if (product?.image) {
                    image = product?.image?.localFile?.publicURL;
                  } else if (product?.data?.product?.productGallery?.[0]?.sourceUrl) {
                    image =
                      product?.data?.product?.productGallery[0]?.sourceUrl;
                  }
                  return (
                    <div className={classes.product} key={index}>
                      <button onClick={() => removeFromWishlist(product?.code)}>
                        <span role="img" aria-label="remove">
                          ×
                        </span>
                      </button>
                      <Link
                        to={product?.data?.uri}
                        style={{ textDecoration: "none" }}
                      >
                        {product?.data && (
                          <img
                            src={image}
                            alt={product?.data?.title}
                            className="placeholder"
                          />
                        )}
                      </Link>
                      <Link
                        to={product?.data?.uri}
                        style={{ textDecoration: "none" }}
                        className="product-info-link"
                      >
                        <span className="product-info">
                          <span className="title">{product?.data?.title}</span>
                          <span className="code">{product?.code}</span>
                        </span>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="actions">
                {isBrowser && (
                  <PDFDownloadLink
                    document={<Pdf products={filteredProducts} />}
                    fileName="quotes.pdf"
                    className="download"
                  >
                    {({ blob, url, loading, error }) => {
                      if (loading) {
                        return "Generating...";
                      }
                      return "Download PDF";
                    }}
                  </PDFDownloadLink>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={classes.qbSearch}>
                <div className="content">
                  <h4>You've not added any products yet!</h4>
                  <p>
                    Search and add your products to the quote builder and then
                    send to us to provide a quotation.
                  </p>
                </div>
                <MiniSearch />
              </div>
            </>
          )}
        </div>
        <Form products={filteredProducts} />
      </section>
      {filteredProducts.length <= 0 ? (
        <section>
          <CgbPopularProductsBlock
            attributes={{
              className: "",
            }}
          />
        </section>
      ) : null}
      <section>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="left"
          alignItems="center"
          className={`image-link-row ${classes.imageLinkRow} ${classes.imageLinkRowTheme}`}
        >
          <Grid item xs={12} md={4}>
            <CgbImageLinkBlock
              attributes={{
                text: "Contact the team",
                linkURL: "/about-us/contact-us/",
                mediaUrl: qbContact,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CgbImageLinkBlock
              attributes={{
                text: "Frequently asked questions",
                linkURL: "/about-us/faqs",
                mediaUrl: qbFaq,
              }}
            />
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default connector(QuoteBuilder);
