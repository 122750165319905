import React from "react";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import { withStyles } from "@material-ui/styles";

import { addToWishlist, removeFromWishlist } from "../../app/actions";
import styles from "./styles";

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (item) => dispatch(addToWishlist(item)),
  removeFromWishlist: (item) => dispatch(removeFromWishlist(item)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function MiniProductSearch(props) {
  const [search, setSearch] = React.useState("");

  function handleSubmit(e) {
    e.preventDefault();

    // send search state to product-search page via navigate
    navigate("/product-search", { state: { query: search } });
  }

  return (
    <div className="search">
      <form onSubmit={handleSubmit}>
        <label htmlFor="search">Search our products</label>
        <input
          type="text"
          name="search"
          placeholder="Name, Product, Brand, Search"
          value={search}
          minLength={2}
          required
          onChange={(e) => setSearch(e.target.value)}
        />
        <input type="submit" value="search" />
      </form>
    </div>
  );
}

export default withStyles(styles)(connector(MiniProductSearch));
